import { ShockAlert } from "../../models/shock-models";
import "../../styles/shock-info.scss";

type ShockInfoProps = {
  alert: ShockAlert;
};

const ShockInfo = (props: ShockInfoProps) => {
  return (
    <div className="info-window-wrapper">
      <p className="info-window-header">
        P(Breakage): {props.alert.pbreach}%
      </p>
      <div className="info-window-content">
        <p className="description">Description</p>
        <p className="description-item">{props.alert.description}</p>
      </div>
    </div>
  );
};

export default ShockInfo;
