import {
    SHOCK_INFO
} from "../common/constants";
import { ShockResponse } from "../models";
import { get } from "./base-service";

export const getShockInfo = (shipmentId: string, startTime: string, endTime: string, token: string): Promise<ShockResponse> => {
  return get(SHOCK_INFO + shipmentId + 
    (startTime ? "&startTime=" + startTime : '') +
    (endTime ? "&endTime=" + endTime : ''), token);
};
