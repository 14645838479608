import { SearchOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import dataMaster from "../../configs/data-master.json";
import { AlertStatus, MasterDataDisplay } from "../../models";
import "../../styles/search-form.scss";
import { AutoCompleteTextBox } from "./autocomplex-textbox";
import dayjs from 'dayjs';
type Dayjs = dayjs.Dayjs;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface SearchFormProps {
  onSearch: (value: any) => void,
  onFilterDateChange: (value: RangeValue) => void,
  masterData?: MasterDataDisplay;
}

function SearchForm(props: SearchFormProps) {
  const { RangePicker } = DatePicker;
  const { keycloak } = useKeycloak();
  const [form] = Form.useForm();
  const { onSearch, masterData, onFilterDateChange } = props;
  const [alert_status, setAlertStatus] = useState(dataMaster.alertStatus);

  useEffect(() => {
    const tokenObj = jwtDecode(keycloak.token!) as any;
    if (tokenObj.hasOwnProperty("customerId")) {
      form.setFieldValue("customerId", tokenObj.customerId);
    }
  }, []);

  // Handle search shipment
  const handleSearch = async (value: any) => {
    onSearch(value);
  };

  // Handle check disable Alert Status
  function isDisableStatus(currentStatus: any, selectedStatus: number[]) {
    return (
      selectedStatus.includes(AlertStatus.NoAlert) ||
      selectedStatus.includes(AlertStatus.Multiple) ||
      (!selectedStatus.includes(AlertStatus.NoAlert) &&
        currentStatus.value === AlertStatus.NoAlert) ||
      (!selectedStatus.includes(AlertStatus.Multiple) &&
        currentStatus.value === AlertStatus.Multiple)
    );
  }
  // Handle change alert Status
  function handleChangeAlertsStatus(selectedStatus: number[]) {
    // If don't select show all from alertStatus
    if (selectedStatus.length === 0) {
      setAlertStatus(dataMaster.alertStatus);
    } else {
      const getAlertStatusDisable = dataMaster.alertStatus.map(
        (currentStatus) => ({
          // Keep all name and value, just change disable status
          ...currentStatus,
          disabled: selectedStatus.includes(currentStatus.value)
            ? false
            : isDisableStatus(currentStatus, selectedStatus),
        })
      );
      setAlertStatus(getAlertStatusDisable);
    }
  }

  return (
    <Form layout="vertical" className="search-form" onFinish={handleSearch} form={form}>
      <fieldset>
        <legend>Filter area</legend>
        <Row gutter={24}>
          <Col span={10}>
            <Card className="search-form-group">
              <Row gutter={24}>
                <Col span={8}>
                  <AutoCompleteTextBox
                    name="shipmentId"
                    label="Shipment ID"
                    placeholder="Enter Shipment ID"
                  />
                </Col>
                <Col span={8}>
                  <Form.Item label="Customer ID" name="customerId">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <AutoCompleteTextBox
                    name="gsId"
                    label="Device ID"
                    placeholder="Enter Device ID"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={10}>
            <Card className="search-form-group">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Origin" name="original">
                    <Select
                      options={masterData?.original}
                      placeholder="Select Origin"
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Destination" name="destination">
                    <Select
                      options={masterData?.destination}
                      placeholder="Select Origin"
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={4}>
            <Card className="search-form-group">
              <Form.Item label="Current Alert Status" name="currentAlertStatus">
                <Select
                  options={alert_status}
                  placeholder="Select Alert Status"
                  mode="multiple"
                  onChange={handleChangeAlertsStatus}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: "10px" }}>
          <Col span={6}>
            <Card className="search-form-group">
              <Row gutter={24}>
                <Col span={24}>
                <Form.Item
                    label={<><span style={{ paddingRight: '122px'}}>Filter Start Time</span><span>Filter End Time</span></>}
                    name="filterTimeRange"
                  >
                  <RangePicker
                    format={"YYYY-MM-DD HH:mm:ss"} showTime 
                    placeholder={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                    onChange={(value: RangeValue) => onFilterDateChange(value)}
                  />
                </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={18} className="form-search-action">
            <Button
              className="btn-search"
              type="primary"
              icon={<SearchOutlined />}
              htmlType="submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}

export default SearchForm;
