import { InfoCircleOutlined } from "@ant-design/icons";
import dataMaster from "../../configs/data-master.json";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import "../../styles/map.scss";

import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { AlertIconPath, AssetIconPath, ShipmentIconPath } from "./icon";
import {
  BaseAsset,
  DashboardSearchResult,
  Position,
  Route,
  SecurityData,
  ShockData,
} from "../../models";
import { MarkerInfo } from "../../models/marker";
import ShipmentRiskInfo from "../shipment-risk-info/shipment-risk-info.component";
import SecurityInfo from "../security-info/security-info.component";
import ShockInfo from "../shock-info/shock-info";

interface MapProps {
  height: string;
  securityDetail?: SecurityData;
  shockDetail?: ShockData;
  shipmentList?: MarkerInfo[];
  shockList?: MarkerInfo[];
  securityList?: MarkerInfo[];
  customLegend?: { icon: string; name: string };
  dashboardData?: DashboardSearchResult;
  selectedMarker: MarkerInfo;
  timePath?: string;
  setSelectedMarker: (marker: MarkerInfo) => void;
}

const Map = (props: MapProps) => {
  const RADIUS_FOR_ONE_MILES = 1609.34;
  const EARTH_RADIUS_BY_MILES = 3958.8;
  // Min zoom = 10 miles
  const MIN_ZOOM_BY_MILES = 10;
  const DEFAULT_ZOOM_BY_MILES = 1000;
  const DEFAULT_LOCATION = {
    lat: 40.327982,
    lng: -96.377093,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
  });

  const {
    height,
    dashboardData,
    customLegend,
    securityDetail,
    shockDetail,
    selectedMarker,
    timePath,
    setSelectedMarker,
  } = props;
  const [isShowLegend, setIshowLegend] = useState(false);
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();

  useEffect(() => {
    if (mapInstance) {
      const bounds = new google.maps.LatLngBounds();
      // In case main dashboard
      if (dashboardData?.assets) {
        dashboardData.assets?.forEach((asset: BaseAsset) => {
          if (asset.lat && asset.lng) {
            bounds.extend(
              new google.maps.LatLng(Number(asset.lat), Number(asset.lng))
            );
          }
        });
      }
      // In case shock dashboard
      if (shockDetail?.routes) {
        shockDetail.routes?.forEach((position) => {
          if (position.lat && position.lng) {
            bounds.extend(
              new google.maps.LatLng(Number(position.lat), Number(position.lng))
            );
          }
        });
      }
      // In case security dashboard
      if (securityDetail?.routes) {
        securityDetail.routes?.forEach((position: Route) => {   
          if (position.lat && position.lng) {
            bounds.extend(
              new google.maps.LatLng(
                Number(position.lat),
                Number(position.lng)
              )
            );
          }
        });
      }
      // If locations are empty, set default location on map
      if (
        (isNotEmpty(dashboardData) ||
          isNotEmpty(shockDetail) ||
          isNotEmpty(securityDetail)) &&
        bounds.isEmpty()
      ) {
        setLocation(DEFAULT_ZOOM_BY_MILES);
        return;
      }
      mapInstance.fitBounds(bounds);
      const distance = calcDistance(
        { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
        { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() }
      );
      // If zoome level < 10 miles, set zoom level to fit 10 miles
      if (distance < 10) {
        setLocation(MIN_ZOOM_BY_MILES, {
          lat: bounds.getCenter()!.lat(),
          lng: bounds.getCenter()!.lng(),
        });
      }
    }
  }, [mapInstance, dashboardData, shockDetail, securityDetail]);

  const onMapClick = () => {
    setSelectedMarker({ ...selectedMarker, isNotSelet: true });
  };

  // Setting default localtion
  const setLocation = (
    zoomInMiles: number,
    defaultLocation = DEFAULT_LOCATION
  ) => {
    var circle = new google.maps.Circle({
      radius: RADIUS_FOR_ONE_MILES * zoomInMiles,
      center: defaultLocation,
    });
    mapInstance!.fitBounds(circle.getBounds()!);
  };

  // Calculate desitance in miles between 2 positions
  const calcDistance = (position1: Position, position2: Position) => {
    var rlat1 = position1.lat * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = position2.lat * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (position2.lng - position1.lng) * (Math.PI / 180);

    return (
      2 *
      EARTH_RADIUS_BY_MILES *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      )
    );
  };

  const isNotEmpty = (obj: unknown) => {
    return obj && Object.keys(obj).length !== 0;
  };

  return (
    <>
      <div className="map-legend">
        {!customLegend?.icon && (
          <div className="map-legend-body">
            <Button
              icon={
                <InfoCircleOutlined
                  onClick={() => setIshowLegend(!isShowLegend)}
                />
              }
            />
            {isShowLegend && (
              <>
                <div className="shiment-description">
                  <label>Shape of Shipment</label>
                  <Row gutter={24}>
                    <Col span={3}>
                      <div className="square-box"></div>
                    </Col>
                    <Col>Not yet started</Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={3}>
                      <div className="triangle-box"></div>
                    </Col>
                    <Col>In transit</Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={3}>
                      <div className="circle-box"></div>
                    </Col>
                    <Col>Reached destination</Col>
                  </Row>
                </div>
                <div className="shiment-description">
                  <label>Color of Alert</label>
                  <Row gutter={24}>
                    <Col span={3}>
                      <div
                        className="square-box"
                        style={{ backgroundColor: dataMaster.alertBgColor.No }}
                      ></div>
                    </Col>
                    <Col>No alert</Col>
                  </Row>
                  {/* Currently not use */}
                  {/* <Row gutter={24}>
                    <Col span={3}>
                      <div
                        className="square-box"
                        style={{ backgroundColor: dataMaster.alertBgColor.Mold }}
                      ></div>
                    </Col>
                    <Col>Water damage</Col>
                  </Row> */}
                  <Row gutter={24}>
                    <Col span={3}>
                      <div
                        className="square-box "
                        style={{
                          backgroundColor: dataMaster.alertBgColor.Shock,
                        }}
                      ></div>
                    </Col>
                    <Col>Shock alert</Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={3}>
                      <div
                        className="square-box "
                        style={{
                          backgroundColor: dataMaster.alertBgColor.security,
                        }}
                      ></div>
                    </Col>
                    <Col>Security alert</Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={3}>
                      <div
                        className="square-box"
                        style={{
                          backgroundColor: dataMaster.alertBgColor.Mutiple,
                        }}
                      ></div>
                    </Col>
                    <Col>Mutiple alert</Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        )}
        {customLegend?.icon && (
          <Row gutter={24} className="single-legend">
            <Col span={3} className="icon">
              <img src={customLegend.icon} width={25} alt='Legend icon' />
            </Col>
            <Col className="text">{customLegend.name}</Col>
          </Row>
        )}
      </div>
      {isLoaded && (
        <GoogleMap
          onLoad={(map) => setMapInstance(map)}
          onClick={onMapClick}
          mapContainerStyle={{ height: height }}
          mapContainerClassName="map-container"
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {/* Main dashboard */}
          {dashboardData?.assets &&
            dashboardData.assets
              .filter((a) => a.lat && a.lng)
              .map((data: BaseAsset, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: data.lat, lng: data.lng }}
                    onClick={() =>
                      setSelectedMarker({
                        gsId: data.gsId,
                        shipmentId: data.shipmentId,
                      })
                    }
                    icon={{
                      url: ShipmentIconPath(data.assetStatus, data.shockFlg, data.securityFlg),
                    }}
                  >
                    {!selectedMarker.isNotSelet &&
                      selectedMarker?.gsId === data.gsId &&
                      selectedMarker?.shipmentId === data.shipmentId && (
                        <InfoWindow>
                          <ShipmentRiskInfo
                            gsId={data.gsId}
                            shipmentId={data.shipmentId}
                            timePath={timePath}
                          ></ShipmentRiskInfo>
                        </InfoWindow>
                      )}
                  </Marker>
                );
              })}

          {/* Shock dashboard */}
          {shockDetail &&
            shockDetail.alerts
              .filter((a) => a.lat && a.lng)
              .map((marker, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    onClick={() =>
                      setSelectedMarker({
                        lat: marker.lat,
                        lng: marker.lng,
                        datetime: marker.datetime,
                        pbreach: marker.pbreach,
                        description: marker.description,
                      })
                    }
                    icon={{
                      url: AlertIconPath(true, false),
                    }}
                    zIndex={100}
                  >
                    {!selectedMarker.isNotSelet &&
                      selectedMarker?.lat === marker.lat &&
                      selectedMarker?.lng === marker.lng && (
                        <InfoWindow>
                          <ShockInfo alert={marker}></ShockInfo>
                        </InfoWindow>
                      )}
                  </Marker>
                );
              })}
          {shockDetail?.routes && (
            <>
              <Polyline
                path={shockDetail?.routes
                  ?.filter((a) => a.lat && a.lng)
                  .map((marker) => {
                    return { lat: marker.lat, lng: marker.lng };
                  })}
                options={{
                  strokeOpacity: 0,
                  icons: [
                    {
                      icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 4,
                        strokeColor: "#db6b22",
                      },
                      offset: "0",
                      repeat: "20px",
                    },
                  ],
                }}
              />
              <Marker
              cursor=""
                position={shockDetail.routes[0]}
                icon={{
                  url: AssetIconPath(0),
                }}
              >
              </Marker>
              <Marker
                position={shockDetail.routes.slice(-1)[0]}
                icon={{
                  url: AssetIconPath(shockDetail.assetStatus),
                }}
              >
              </Marker>
            </>
          )}

          {/* Security dashboard */}
          {securityDetail &&
            securityDetail.alerts
              .filter((a) => a.lat && a.lng)
              .map((marker, index) => {
                  return (
                    <Marker
                      key={index}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() =>
                        setSelectedMarker({
                          lat: marker.lat,
                          lng: marker.lng,
                          datetime: marker.datetime,
                          pbreach: marker.pbreach,
                          description: marker.description,
                        })
                      }
                      icon={{
                        url: AlertIconPath(false, true),
                      }}
                      zIndex={100}
                    >
                      {!selectedMarker.isNotSelet &&
                        selectedMarker?.lat === marker.lat &&
                        selectedMarker?.lng === marker.lng && (
                          <InfoWindow>
                            <SecurityInfo alert={marker}></SecurityInfo>
                          </InfoWindow>
                        )}
                    </Marker>
                  );
              })}
          {securityDetail?.routes && (
            <>
              <Polyline
                path={securityDetail?.routes
                  ?.filter((a) => a.lat && a.lng)
                  .map((marker) => {
                    return { lat: marker.lat, lng: marker.lng };
                  })}
                options={{
                  strokeOpacity: 0,
                  icons: [
                    {
                      icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 4,
                        strokeColor: "#ff0000",
                      },
                      offset: "0",
                      repeat: "20px",
                    },
                  ],
                }}
              />
              <Marker
                position={securityDetail.routes.map(x => {return {lat: x.lat, lng: x.lng}})[0]}
                icon={{
                  url: AssetIconPath(0),
                }}
              >
              </Marker>
              <Marker
                position={securityDetail.routes.slice(-1).map(x => {return {lat: x.lat, lng: x.lng}})[0]}
                icon={{
                  url: AssetIconPath(securityDetail.assetStatus),
                }}
              >
              </Marker>
            </>
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
