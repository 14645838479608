import { Breadcrumb } from "antd";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { BREADCRUMB_NAME_MAP } from "../../common/constants";

export const IraBreadCrumb = () => {
  const location = useLocation();

  const breadcrumbItems = [] as any[];

  const pathSnippet = location.pathname;
  if (pathSnippet !== '/') {
    breadcrumbItems.push(
      <Breadcrumb.Item key="/">
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
    );
    breadcrumbItems.push(
      <Breadcrumb.Item key={pathSnippet}>
        {BREADCRUMB_NAME_MAP[pathSnippet]}
      </Breadcrumb.Item>
    );
  }
  return (
    <div className="ira-breadcrumb">
      <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
    </div>
  );
};
