import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import keycloak from "../../configs/keycloak";
import { getWaterInfo } from "../../services/water-damage-service";
import { NoData } from "../../components/no-data/no-data.component";
import { useDocumentTitle } from "../../hooks/use-document-title";

interface ShockInfoProps {
  location?: { query: { gsId: string } };
}

function WaterDashboard(props: ShockInfoProps) {
  useDocumentTitle("Water Damage Dashboard");
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");

  useEffect(() => {
    setLoading(true);
    const url = new URL(window.location.href);
    const startTime = url.searchParams.get("startTime") ?? '';
    const endTime = url.searchParams.get("endTime") ?? '';
    const shipmentId = url.searchParams.get("shipmentId") ?? '';
    getWaterInfo(shipmentId, startTime, endTime, keycloak.token ?? "")
      .then((res) => {
        if (res?.data?.embedUrl) {
          setHasData(true);
          setEmbedUrl(res?.data?.embedUrl);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return !hasData ? (
    <NoData loading={loading} />
  ) : (
    <>
      <Spin spinning={false}>
        <Row gutter={[24, 24]} className={"landing-page"}>
          <Col span={24} className="gutter-row buffer">
            <div className="chart" id="embed-container" style={{height: 'calc(100vh - 71px)'}}>
              <iframe className="iframe-chart" src={embedUrl} width="100%" height="100%"></iframe>
            </div>
          </Col>
        </Row>
      </Spin>
    </>
  );
}

export default WaterDashboard;
