import { Empty, Skeleton } from 'antd';
import './no-data.scss';

interface NoDataProps {
  loading: boolean;
}

export const NoData = (props: NoDataProps) => {
  const { loading } = props;

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 20 }}>
      <div className="no-data">
        <Empty />
      </div>
    </Skeleton>
  );
};
