import { Divider, Typography } from 'antd';
import { MarkerInfo } from '../../models/marker';

interface propsInfo {
  alert: MarkerInfo
}

function SecurityAlertDetail(props: propsInfo) {
  const { alert } = props;
  return (
    <div className='security-drawer'>
        <Typography className='line-label'>
          Date Time
        </Typography>
        <Divider/>
        <Typography>
          {alert?.datetime}
        </Typography>

        <Typography className='line-label'>
          Location
        </Typography>
        <Divider />
        <Typography>
          {alert?.lat && alert?.lng ? alert?.lat.toFixed(4) + '; ' + alert?.lng?.toFixed(4) : ''}
        </Typography>

        <Typography className='line-label'>
          P(Breach)
        </Typography>
        <Divider />
        <Typography>
          {alert?.pbreach}%
        </Typography>

        <Typography className='line-label'>
          Incident Description
        </Typography>
        <Divider />
        <Typography>
          {alert?.description}
        </Typography>
    </div>
  );
}

export default SecurityAlertDetail;
