export function AlertIconPath(shockFlg: boolean, securityFlg: boolean): string {
    if (shockFlg && securityFlg) {
        return '/icons/multiple.svg';
    } else if (shockFlg) {
        return '/icons/shock.svg';
    } else if (securityFlg) {
        return '/icons/risk.svg';
    } else {
        return '/icons/normal.svg';
    }
}

export function ShipmentIconPath(status: number, shockFlg: boolean, securityFlg: boolean) {
    let prefix = './icons/'
    let suffix = '.svg';
    let statusStr = (status === 1) ? "transit_" : "end_";
    let typeStr = "normal";

    if (shockFlg && securityFlg) {
        typeStr = "multiple";
    } else if (shockFlg) {
        typeStr = "shock";
    } else if (securityFlg) {
        typeStr = "security";
    }

    return prefix + statusStr + typeStr + suffix;
}

export function AssetIconPath(status: number) {
    if (status === 0) {
        return './icons/start.svg'
    }

    if (status === 1) {
        return './icons/transit.svg'
    }
    return '/icons/end.svg';
}