import { PagingInfo } from "./pagingInfo";

export interface SearchFilter extends PagingInfo {
  source: string,
  gsId: string,
  alertCounter: string,
  msgType: MessageType,
  missionMode: MissionMode,
  masterStatus: MasterStatus,
  armState: ArmState,
  powerState: PowerState,
  inZone: string,
  currentAlertStatus: string,
  startTime?: string,
  endTime?: string,
  displayTime: Date,
  original?: string[],
  destination?: string[]
}

export interface SearchFilterByTime extends PagingInfo{
  shipmentIds: string,
  startTime?: string,
  endTime: string,
}

export enum MessageType {
  GSU_Status = 1,
  GSU_WaypointStatus = 2,
  GSU_Alert = 3,
  GSU_AlertGps = 4,
  GSU_GpsFollowUp = 5,
  GSU_AlertFollowUp = 6,
  GSU_Carrier = 7,
  GSU_Shock = 8,
  GSU_SensorLog = 9,
  GSU_SensorEnhLog = 10,
  MESH_Gps = 11,
  MESH_Sensor = 12,
  DMC_GeoEntry = 13,
  DMC_GeoExit = 14,
  DMC_TripStarted = 15,
  DMC_TripCompleted = 16,
  DMC_TripCancelled = 17,
  Unknown = 99,
}

export enum MissionMode {
  On = 1,
  Off = 2,
}

export enum MasterStatus {
  Opening = 1,
  Open = 2,
  Closing = 3,
  Closed = 4,
  Open_Unmounted = 5,
  Open_Mounted = 6,
  Unknown = 99,
}

export enum ArmState {
  Arming = 1,
  Armed = 2,
  Disarmed = 3,
  Unknown = 99,
}

export enum PowerState {
  OnExternalPower = 1,
  OffExternalPower = 2,
}

export enum AssetStatus {
  NotYetStarted = 1,
  InTransit = 2,
  ReachedDestination = 3,
}

export enum AlertStatus {
  NoAlert = 0,
  SecurityAlerts = 1,
  ShockAlerts = 2,
  Multiple = 99,
}