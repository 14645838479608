import { useKeycloak } from "@react-keycloak/web";
import { FC } from "react";

interface Props {
  children: any;
}

export const PrivatRoute: FC<Props> = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  return isLoggedIn ? children : null;
};
