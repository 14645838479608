import { AlertStatus, AssetStatus } from "./shipment";

// Base Alert info
export interface BaseAlert {
  gsId: string,
  shipmentId: string,
  datetime: string,
  description: string,
  lat: number,
  lng: number,
  type: number,
}
// Base Asset info
export interface BaseAsset {
  gsId: string,
  shipmentId: string,
  assetStatus: AssetStatus,
  alertStatus: AlertStatus,
  dateTime: Date,
  lat: number,
  lng: number,
  alerts: BaseAlert[],
  securityFlg: boolean,
  shockFlg: boolean,
}

export interface Route {
    lat: number, 
    lng: number
    locationName: string,
}

export enum AlertType {
  Shock = 1,
  Security = 2,
  WaterDamage = 3
}

export interface Message {
  messageCode: string,
  messageContent: string,
}