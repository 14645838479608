import { SecurityAlert } from "../../models/security-models";
import "../../styles/security-info.scss";

type SecurityInfoProps = {
  alert: SecurityAlert;
};

const SecurityInfo = (props: SecurityInfoProps) => {
  return (
    <div className="security-info-window-wrapper">
      <p className="info-window-header">P(breach): {props.alert.pbreach}%</p>
      <div className="info-window-content">
        <p className="reasons">Reasons</p>
        {props.alert.reasons.map((reason, index) => (
          <p key={index} className="risk-item">
            {reason}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SecurityInfo;
