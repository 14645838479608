import { ConfigProvider } from "antd";
import "./App.scss";
import { BasicLayout } from "./layouts/basic-layout";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#c00",
        },
      }}
    >
      <BasicLayout />
    </ConfigProvider>
  );
}

export default App;
