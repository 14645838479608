import { ExclamationCircleFilled, WarningOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AlertType, BaseAlert } from "../../models";
import "../../styles/latest-alert.scss";

interface latestAlertsProps {
  latestAlerts: BaseAlert[];
}

function LatestAlert({ latestAlerts }: latestAlertsProps) {
  return (
    <div className="lasted-alert">
      {latestAlerts &&
        latestAlerts?.length > 0 &&
        latestAlerts.map((el, index) => (
          <p
            key={index}
            className={`
        ${el.type === AlertType.Security ? "security-alert" : ""}
        ${el.type === AlertType.Shock ? "shock-alert" : ""}
        `}
          >
            {el.type === AlertType.Security ? (
              <ExclamationCircleFilled />
            ) : (
              <WarningOutlined />
            )}
            <span>{el.datetime}</span>
            <Link
              target="_blank"
              to={{
                pathname: `${
                  el.type === AlertType.Security
                    ? "/security-dashboard"
                    : "/shock-dashboard"
                }`,
                search: `?shipmentId=${el.shipmentId}`,
              }}
              className="link-cell"
            >
              <span>{`${el.description}`}</span>
            </Link>
          </p>
        ))}
    </div>
  );
}

export default LatestAlert;
