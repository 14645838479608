import { Table } from "antd";
import { useEffect, useState } from "react";
import { MainAlertInfo, MarkerInfo } from "../../models/marker";
import "../../styles/alert-list.scss";
import AntPagination from "../pagination/ant-pagination.component";

interface AlertListProps {
  alertList: MainAlertInfo[];
  tableConfig: any;
  onRowClick: (row: MainAlertInfo) => void;
  selectedRow?: MarkerInfo;
  screenHeightWithoutMap: number;
  reloadCounter: number; // Increase each time data is reload to reset page/size
}

function AlertList(props: AlertListProps) {
  const { alertList, tableConfig, selectedRow, onRowClick, screenHeightWithoutMap, reloadCounter } = props;
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize] = useState(50);

  const handlePageChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  }

  // When alert list change, reset pagination to default
  useEffect(() => {
    setPageSize(50);
    setPage(1);
  }, [reloadCounter]);

  return (
    <div>
      <Table
        className="alert-list-table" /** Don't show  pagination of the table*/
        onRow={(record: MainAlertInfo) => {
          return {
            onClick: () => {
              onRowClick?.(record)
            }
          }
        }}
        rowClassName={(rowData) => {return !selectedRow?.isNotSelet && selectedRow?.gsId && selectedRow?.shipmentId 
          &&  selectedRow.gsId === rowData?.gsId && selectedRow.shipmentId === rowData?.shipmentId ? "selected-row" : ""}}
        columns={tableConfig}
        dataSource={
          alertList &&
          alertList.map((alert, index) => ({ ...alert, key: index } as any))
        }
        scroll={{ y: `calc(100vh - ${screenHeightWithoutMap + 40}px)`}}
        bordered
        size="small"
        pagination={{
          pageSize: pageSize,
          current: page,
        }}
      />
      <AntPagination
        size="small"
        className="mt-10"
        total={alertList?.length}
        showSizeChanger={true}
        isShowTotal={false}
        current={page}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
    </div>
  );
}

export default AlertList;
