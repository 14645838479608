import { Link } from "react-router-dom";

const shockAlertTableConfig = () => [
  {
    title: 'Date Time',
    dataIndex: 'dateTime',
    key: 'dateTime',
    width: '10%',
    defaultSortOrder: 'descend',
    sorter: (a: {dateTime: string}, b: {dateTime: string}) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
  },
  {
    title: 'Shipment ID',
    dataIndex: 'shipmentInfo',
    key: 'shipmentInfo',
    width: '7%',
    ellipsis: true,
    render: (value: any) => (
      <Link
        to={{
          pathname: "/shock-dashboard",
          search: "?shipmentId=" + value.split(";")[0] + value.split(";")[1]
        }}
        target="_blank"
      >
        <span className="link-cell">{value.split(";")[0]}</span>
      </Link>
    ),
  },
  {
    title: 'Device ID',
    dataIndex: 'gsId',
    key: 'gsId',
    width: '7%',
    ellipsis: true,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: '10%',
    ellipsis: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '15%',
    ellipsis: true,
  },
];

export { shockAlertTableConfig };