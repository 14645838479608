import { useKeycloak } from "@react-keycloak/web";
import { AutoComplete, Form } from "antd";
import debounce from "lodash/debounce";
import { useState } from "react";
import { SuggestionInput } from "../../models/suggestion";
import { getSuggestion } from "../../services/main-service";

type AutoCompleteTextBoxPropsType = {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
};
export const AutoCompleteTextBox = ({
  name,
  label,
  placeholder,
  disabled,
  ...props
}: AutoCompleteTextBoxPropsType) => {
  const { keycloak } = useKeycloak();
  const [options, setOptions] = useState([]);

  // Get Suggestions when having value in the textbox
  const handleSearch = async (txt: any) => {
    try {
      console.log(txt);
      if (!txt) {
        setOptions([]);
        return;
      }
      let params = {} as SuggestionInput;
      switch (name) {
        case "gsId":
          params = { gsId: txt };
          break;
        case "shipmentId":
          params = { shipmentId: txt };
          break;
        case "customerId":
          params = { customerId: txt };
          break;
        default:
          break;
      }
      const getSuggestionResponse = await getSuggestion(params, keycloak.token!);
      const _options = getSuggestionResponse?.data?.map((item: any) => ({
        label: item,
        value: item,
      }));
      setOptions(_options && _options.length > 0 ? _options : [{label: "No data"}]);
    } catch (error) {
      console.log("AutoCompleteTextBox/ handleSearch - error: ", error);
    }
  };

  return (
    <Form.Item label={label} name={name}>
      <AutoComplete
        {...props}
        options={options}
        className="auto-complete"
        placeholder={placeholder}
        onChange={debounce(handleSearch, 500)}
      ></AutoComplete>
    </Form.Item>
  );
};
