import { Button, Result } from "antd";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log(error);
      console.log(errorInfo);
    }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          subTitle="Sorry, something went wrong"
          extra={
            <Button type="primary" onClick={() => window.location.href='/'} >
              Back Home
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}
