export const MASTER_DATA = '/masterData';
export const SEARCH_SHIPMENTS = '/searchShipments';
export const SEARCH_SHIPMENTS_BY_DISPLAY_TIME = '/searchShipmentsByDisplayTime';
export const SUGGEST_FILTER = '/suggestFilter';
export const LATEST_ALERT = '/latestAlert';
export const SHIPMENT = '/shipment';
export const SECURITY_INFO = '/securityData?shipmentId=';
export const SHOCK_INFO = '/shock?shipmentId=';
export const WATER_DAMAGE_INFO = '/water?shipmentId='

export const BREADCRUMB_NAME_MAP: Record<string, string> = {
    '/security-dashboard': 'Security Alerts',
    '/shock-dashboard': 'Shock Alerts',
    '/water-damage-dashboard': 'Water Damage',
  };