import Map from '../../components/map/map.component';
import { NoData } from '../../components/no-data/no-data.component';
import SecurityAlertDetail from '../../components/security-alert-detail/security-alert-detail.component';
import SecurityAlertTable from '../../components/security-alert-table/security-alert-table.component';
import keycloak from '../../configs/keycloak';
// import { shipmentService } from '@/services/shipment.service';
import { getSecurityInfo } from '../../services/security-service';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Alert, Col, Drawer, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import dataMaster from '../../configs/data-master.json';
import { SecurityAlert, SecurityData } from '../../models/security-models';
import { Route } from '../../models/alert';
import '../../styles/security-dashboard.scss';
import { MarkerInfo } from '../../models/marker';
import { useDocumentTitle } from '../../hooks/use-document-title';

function SecurityDashboard() {
  useDocumentTitle("Security Alerts Dashboard");
  const [loading, setLoading] = useState(true);
  const [securityInfo, setSecurityInfo] = useState<SecurityData>();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<MarkerInfo>({isNotSelet: true});

  useEffect(() => {
    setLoading(true);
    const url = new URL(window.location.href);
    const startTime = url.searchParams.get("startTime") ?? '';
    const endTime = url.searchParams.get("endTime") ?? '';
    const shipmentId = url.searchParams.get("shipmentId");

    getSecurityInfo(shipmentId ?? '', startTime, endTime, keycloak?.token ?? '')
    .then(res => {
      if (res.data) {
        res.data.routes = res.data.routes.filter((m: Route) => m.lat && m.lng);
        setSecurityInfo(res.data);
        setHasData(true);
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsOpenDrawer(!selectedMarker.isNotSelet);
  }, [selectedMarker])

  const mapPanelData = (alerts: SecurityAlert[]): MarkerInfo[] => {
    return alerts?.map(data => {
      return {
        lat: data.lat,
        lng: data.lng,
        dateTime: data.datetime,
        location: data.lat && data.lng ? (data.lat?.toFixed(4)  + "; " + data.lng.toFixed(4)) : '-',
        datetime: data.datetime,
        description: data.description,
        reasons: data.reasons,
        pbreach: data.pbreach,
      };
    });
  };

  return !hasData ? (<NoData loading={loading} />) : (
    <>
      <Spin spinning={loading} delay={300}>
          <Row gutter={[24, 24]} className={'security-page'}>
            <Col
              className="gutter-row"
              xl={16}
              lg={16}
            >
              <Row gutter={[24, 24]}>
                <Col span={6} className="gutter-row buffer">
                  <Alert
                    message={ `Cumulative P(Breach): ${!securityInfo?.alerts || securityInfo?.alerts.length === 0 ||  !securityInfo?.cumulative  ? 0 : securityInfo?.cumulative}%`}
                    showIcon
                    icon={<ExclamationCircleFilled style={{ color: dataMaster.alertBgColor.security }} />}
                    className="cumulative-alert"
                  />
                </Col>
                <Col span={6} className="gutter-row buffer">
                  <Alert
                    message={`Total Incidence: ${!securityInfo?.alerts ? 0 : securityInfo?.alerts.length}`}
                    showIcon
                    icon={<ExclamationCircleFilled style={{ color: dataMaster.alertBgColor.security }} />}
                    className="cumulative-alert"
                  />
                </Col>
                <Col
                  span={24}
                  className="gutter-row"
                  style={{ marginTop: '10px' }}
                >
                  <Map
                    securityDetail={securityInfo}
                    height={'calc(100vh - 130px)'}
                    customLegend={{
                      icon: '/icons/risk.svg',
                      name: 'Security alert',
                    }}
                    selectedMarker={selectedMarker}
                    setSelectedMarker={setSelectedMarker}
                  ></Map>
                </Col>
              </Row>
            </Col>
            <Col
              xl={8}
              lg={8}
            >
              <Row gutter={[24, 24]}>
                <Col className="gutter-row">
                  <SecurityAlertTable
                    tableAlertList={mapPanelData(securityInfo?.alerts ?? []) as any}
                    onRowClick={(row: MarkerInfo) => {
                      setSelectedMarker(row);
                    }}
                  ></SecurityAlertTable>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col className="gutter-row"></Col>
              </Row>
            </Col>
          </Row>
      </Spin>
      <Drawer 
        className='drawer'
        placement="right" 
        open={isOpenDrawer} 
        mask={false} 
        closable={true}
        onClose={() => setIsOpenDrawer(false)}
        width={'33%'}
        title={
          <span className='security-title-panel'>
            <ExclamationCircleFilled 
              className='security-alert-icon' 
              style={{ color: dataMaster.alertBgColor.security }} />Security Alert Details</span>
        }
      >
        <SecurityAlertDetail alert={selectedMarker}></SecurityAlertDetail>
      </Drawer>
    </>
  );
}

export default SecurityDashboard;
