import { Routes, Route } from "react-router";
import { HeaderComponent } from "../components/header/header.component";
import MainDashBoard from "../pages/main-dashboard/main-dashboard.component";
import { NotFoundComponent } from "../pages/not-found/not-found.component";
import SecurityDashboard from "../pages/security-dashboard/security-dashboard.component";
import ShockDashboard from "../pages/shock-dashboard/shock-dashboard.component";
import WaterDashboard from "../pages/water-dashboard/water-dashboard.component";
import ErrorBoundary from "./error-boundary";

export const BasicLayout = () => {
  return (
    <ErrorBoundary>
      <HeaderComponent />
      <div className="ira-main">
        <Routes>
          <Route path="/" element={<MainDashBoard />} />
          <Route path="/shock-dashboard" element={<ShockDashboard />} />
          <Route path="/security-dashboard" element={<SecurityDashboard />} />
          <Route path="/water-damage-dashboard" element={<WaterDashboard />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
};
