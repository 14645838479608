import {
    WATER_DAMAGE_INFO
} from "../common/constants";
import { WaterResponse } from "../models/water-models";
import { get } from "./base-service";

export const getWaterInfo = (shipmentId: string, startTime: string, endTime: string, token: string): Promise<WaterResponse> => {
  return get(WATER_DAMAGE_INFO + shipmentId + 
    (startTime ? "&startTime=" + startTime : '') +
    (endTime ? "&endTime=" + endTime : ''), token);
};
