import { MasterDataDisplay } from "../models/master-data";
import { DefaultOptionType } from "antd/es/select";
import { MasterDataResponse } from "../models";

export const buildMasterData = (masterRes: MasterDataResponse) => {
  const original = masterRes.data.original.map((str) => {
    return {
      label: str,
      value: str,
    } as DefaultOptionType;
  });

  const destination = masterRes.data.destination.map((str) => {
    return {
      label: str,
      value: str,
    } as DefaultOptionType;
  });

  return {
    original: original,
    destination: destination,
  } as MasterDataDisplay;
};
