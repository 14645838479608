const shockAlertTableConfig = () => [
  {
    title: 'Date Time',
    dataIndex: 'datetime',
    key: 'datetime',
    width: '9%',
    defaultSortOrder: 'descend',
    sorter: (a: {dateTime: string}, b: {dateTime: string}) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    width: '10%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '11%',
    ellipsis: true,
  },
];

export { shockAlertTableConfig };
