import {
  LATEST_ALERT,
  MASTER_DATA,
  SEARCH_SHIPMENTS,
  SEARCH_SHIPMENTS_BY_DISPLAY_TIME,
  SUGGEST_FILTER,
} from "../common/constants";
import {
  DashboardSearchResponse,
  MasterDataResponse,
  SearchFilter,
  SearchFilterByTime,
} from "../models";
import { SuggestionInput } from "../models/suggestion";
import { get } from "./base-service";

/**
 * Get init data for all search dropdown item
 * @param token Access token
 * @returns List master data
 */
export const getMasterData = (token: string): Promise<MasterDataResponse> => {
  return get(MASTER_DATA, token);
};

/**
 * Get list lasted alert
 * @param token Access token
 * @returns List lasted alert
 */
export const getLatestAlert = (customerId: string, token: string) => {
  return get(LATEST_ALERT, token, {customerId: customerId});
};

/**
 * Get list shipment, include alerts list
 * @param params Search condition
 * @param token Access token
 * @returns List shipment and alert
 */
export const searchShipments = (
  params: SearchFilter,
  token: string
): Promise<DashboardSearchResponse> => {
  return get(SEARCH_SHIPMENTS, token, params);
};

/**
 * Get list shipment by display time
 * @param params Search condition
 * @param token Access token
 * @returns List shipment
 */
export const searchByDisplayTime = (
  params: SearchFilterByTime,
  token: string
): Promise<DashboardSearchResponse> => {
  //Search shipments with params type FilterData
  return get(SEARCH_SHIPMENTS_BY_DISPLAY_TIME, token, params);
};

/**
 * Get data suggestion for textbox
 * @param keyword Keyword
 * @param token Access token
 * @returns Suggest data list
 */
export const getSuggestion = (suggestionInput: SuggestionInput, token: string) => {
  return get(SUGGEST_FILTER, token, suggestionInput);
};