import "../../styles/header.scss";
import { Col, Row } from "antd";
import { RightContent } from "./right-content.component";
import { IraBreadCrumb } from "./breadcrumb.component";
import { Link } from "react-router-dom";

export const HeaderComponent = () => {
  return (
    <div className="ira-header">
      <Row gutter={24} style={{ height: "100%" }}>
        <Col span={12} className="left-content">
          <div className="ira-logo">
            <Link to="">
              <h1>IoT - Risk Analytics</h1>
            </Link>
          </div>
          <IraBreadCrumb />
        </Col>
        <Col span={12}>
          <RightContent />
        </Col>
      </Row>
    </div>
  );
};
