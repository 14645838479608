import { Pagination, Select } from 'antd';
import { useEffect, useState } from 'react';

interface paginationProps {
  size: "small" | "default" | undefined,
  className: string,
  total?: number,
  onChange?: (page: number, pageSize: number) => void,
  pageSize?: number,
  showSizeChanger: boolean,
  isShowTotal?: boolean,
  current?: number,
}

function AntPagination(props: paginationProps) {
  const {
    onChange,
    pageSize = 50,
    current = 1,
    showSizeChanger,
    isShowTotal = true,
    ...otherProps
  } = props;

  const { Option } = Select;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(50);

  // Change page and pageSize from parent
  useEffect(() => {
    setCurrentPage(current);
  }, [current])
  useEffect(() => {
    setCurrentPageSize(pageSize);
  }, [pageSize]);

  const showTotal = isShowTotal ?
    (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total || 0
      } items` : undefined;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onChange?.(page, currentPageSize);
  };

  const handlePageSizeChange = (value: number) => {
    setCurrentPageSize(value);
    setCurrentPage(1);
    onChange?.(1, value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
      <Pagination
        current={currentPage}
        pageSize={currentPageSize}
        onChange={handlePageChange}
        showTotal={showTotal}
        showSizeChanger={false} // Show custom size changer instead of the core
        {...otherProps}
      />
      {showSizeChanger &&
        <Select
          size='small'
          value={currentPageSize}
          dropdownMatchSelectWidth={false}
          onChange={handlePageSizeChange}>
          <Option value={50}>50 / page</Option>
          <Option value={100}>100 / page</Option>
          <Option value={150}>150 / page</Option>
        </Select>}
    </div>
  );
}

export default AntPagination;
