import { Table } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { securityTableConfig } from './security-alert-table-config';
import dataMaster from '../../configs/data-master.json';
import '../../styles/security-alert-table.scss';
import { MainAlertInfo } from '../../models/marker';

interface SecurityAlertTableProps {
  tableAlertList: {dateTime: Date, location: string, pbreach: string}[],
  onRowClick: (row: MainAlertInfo) => void;
}

function SecurityAlertTable(props: SecurityAlertTableProps) {
  const { tableAlertList, onRowClick } = props;
  return (
    <>
      <div className='security-header-table table-title'>
        <ExclamationCircleFilled style={{ color: dataMaster.alertBgColor.security, margin: '0 10px' }} />
        <span className='security-title-header-table'>
          Security Alerts
        </span>
      </div>
      <Table
        size="small"
        className="alert-table security-table"
        onRow={(record: MainAlertInfo) => {
          return {
            onClick: () => {
              onRowClick?.(record)
            }
          }
        }}
        columns={securityTableConfig() as any}
        dataSource={tableAlertList && tableAlertList.map((alert, index) => ({ ...alert, key: index } as any))}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
          pageSizeOptions: [50, 100, 150],
        }}
      />
    </>
  );
}

export default SecurityAlertTable;
