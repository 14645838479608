import { LogoutOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { Dropdown, MenuProps } from "antd";
import { useEffect, useState } from "react";

const currentTimeWithFormat = () => {
  const now = new Date();
  const utcDate = now.toISOString().slice(0, 19).replace("T", " ");

  return `${utcDate}  +00:00`;
};

export const RightContent = () => {
  const { keycloak } = useKeycloak();
  const [currentTime, setCurrentTime] = useState(() => currentTimeWithFormat());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentTime(currentTimeWithFormat());
    }, 1000);
    return () => clearInterval(secTimer);
  }, []);

  const handleLogout = () => {
    window.location.href = `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.href}&id_token_hint=${keycloak.idToken}`;
  };

  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "1",
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];
  return (
    <div className="right-content">
      <div className="current-time">{currentTime}</div>
      <Dropdown menu={{ items }}>
        <span>
          {keycloak.idTokenParsed?.name
            ? keycloak.idTokenParsed?.name
            : keycloak.idTokenParsed?.preferred_username}
        </span>
      </Dropdown>
    </div>
  );
};
