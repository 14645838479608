import { ExclamationCircleFilled, WarningOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AlertList from "../../components/alert-list/alert-list.component";
import { shockAlertTableConfig } from "../../components/alert-list/shock-alert-table-config";
import LatestAlert from "../../components/latest-alert/latest-alert";
import Map from "../../components/map/map.component";
import {
  BaseAlert,
  DashboardSearchResult,
  MasterDataDisplay,
  PagingInfo,
  SearchFilter,
  SearchFilterByTime,
} from "../../models";
import { MainAlertInfo, MarkerInfo } from "../../models/marker";
import {
  getLatestAlert,
  getMasterData,
  searchByDisplayTime,
  searchShipments,
} from "../../services/main-service";
import "../../styles/main-dashboard.scss";
import dayjs from 'dayjs';

import { securityAlertTableConfig } from "../../components/alert-list/security-alert-table-config";
import { alertTableConfig } from "../../components/alert-list/water-damage-table-config";
import SearchForm from "../../components/search-form/search-form.component";
import dataMaster from "../../configs/data-master.json";
import { buildMasterData } from "../../helpers/main-dashboard.helper";
import jwtDecode from "jwt-decode";
import AntPagination from "../../components/pagination/ant-pagination.component";

type Dayjs = dayjs.Dayjs;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const MainDashBoard = () => {
  const { keycloak } = useKeycloak();
  const [isDisableDisplayTime, setIsDisableDisplayTime] = useState(true);
  const [displayTime, setDisplayTime] = useState("");
  const [latestAlerts, setLatestAlerts] = useState<BaseAlert[]>([]);

  const [paging, setPaging] = useState({page: 1, limit: 50, total: 0} as PagingInfo);
  const [refreshTime, setRefreshTime] = useState(15);
  const [loading, setLoading] = useState(true);
  const [screenHeightWithoutMap, setScreenHeightWithoutMap] = useState(417);
  const [showUrgent, setShowUrgent] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<MarkerInfo>({});
  const [masterData, setMasterData] = useState<MasterDataDisplay>();
  const [reloadCounter, setReloadCounter] = useState(0);
  const [forceReload, setForceReload] = useState(Date.now);
  const [startDate, setStartDate] = useState<Dayjs | null>();
  const [endDate, setEndDate] = useState<Dayjs | null>();
  const [shipmentId, setShipmentId] = useState("");
  const [timePath, setTimePath] = useState<string>('');

  const displayTimeRef = useRef("");

  const [assetSearchResult, setAssetSearchResult] = useState(
    {} as DashboardSearchResult
  );

  // Get master data
  useEffect(() => {
    sessionStorage.removeItem("searchParams");

    // Get master data
    getMasterData(keycloak.token ?? "").then((res) => {
      setMasterData(buildMasterData(res));
    });

    // Get latest alert
    const tokenObj = jwtDecode(keycloak.token!) as any;
    let customerId = "";
    if (tokenObj.hasOwnProperty("customerId")) {
      customerId = tokenObj.customerId;
    }
    getLatestAlert(customerId, keycloak.token ?? "").then((res) => {
      setLatestAlerts(res.data);
    });

    // Search on page load
    getData(getSearchParam(paging));
  }, []);

  // Refresh interval
  useEffect(() => {
    let intervalId = setInterval(() => {
      getData(getSearchParam(paging));
    }, refreshTime * 60 * 1000);

    if (refreshTime === 0) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [refreshTime, forceReload]);

  // Set display time for display time ref when it's changed
  useEffect(() => {
    displayTimeRef.current = displayTime;
  }, [displayTime]);

  const onSearchSuccess = (result: DashboardSearchResult) => {
    clearSelectedMarker();

    setTimePath(getPathParamWithTime);

    setAssetSearchResult(result);
    // Update alert tables
    setReloadCounter(reloadCounter + 1);
  }

  const getPathParamWithTime = (): string => {
    let start = startDate ? "startTime=" + formatDate(startDate) : "";
    let end = displayTimeRef.current ? "endTime=" + displayTimeRef.current : (endDate) ? "endTime=" + formatDate(endDate) : "";
    return [start, end].filter(time => time).join("&");
  }

  // On change page number or page size
  // => re search
  const onPageChange = (page: number, limit: number) => {
    setPaging({
      ...paging,
      page: page,
      limit: limit,
    });
    getData(
      getSearchParam({
        page: page,
        limit: limit,
      })
    );
  };

  // Search shipment
  const onSearch = (value: any) => {
    let params: SearchFilter = {
      page: 1,
      limit: 50,
    } as SearchFilter;
    
    params.currentAlertStatus = value.currentAlertStatus?.join();
    params.startTime = value.filterTimeRange ? formatDate(value.filterTimeRange[0]) : undefined;
    params.endTime = value.filterTimeRange ? formatDate(value.filterTimeRange[1]) : undefined;
    if (value.original && value.original.length > 0) {
      params.original = value.original?.join();
    }
    if (value.destination && value.destination.length > 0) {
      params.destination = value.destination?.join();
    }
    
    delete value.filterTimeRange;
    delete value.currentAlertStatus;
    delete value.original;
    delete value.destination;

    params = { ...params, ...value };

    // Set search history
    sessionStorage.setItem("searchParams", JSON.stringify(params));
    getData(params);
  };

  // Change display time
  const onChangeIntervalTime = (value: number) => {
    setRefreshTime(value);
    if (value !== 0) {
      if (!isDisableDisplayTime) {
        // If don't select display time, get list shipment base on prev search condition (if have)
        getData(getSearchParam(paging));
      }
      setIsDisableDisplayTime(true);
      setDisplayTime("");
      return;
    }
    setIsDisableDisplayTime(false);
  };

  const formatDate = (value: Dayjs) => {
    return value.format("YYYY-MM-DD HH:mm:ss");
  }


  // Change display time
  const onChangeDisplayTime = (value: Dayjs | null) => {
    // If display time is selected
    if (value) {
      setDisplayTime(formatDate(value));
      setTimeout(() => {
        getDataByDisplayTime();
      });
    } else {
      // If don't select display time, get list shipment base on prev search condition (if have)
      getData(getSearchParam(paging));
      setDisplayTime("");
    }
  };

  const getDataByDisplayTime = () => {
    setLoading(true);
    let param: SearchFilterByTime = {
      displayTime: displayTimeRef.current,
      startTime: startDate ? formatDate(startDate) : null,
      endTime: displayTimeRef.current,
      shipmentIds: shipmentId,
    } as SearchFilterByTime;
    !param.startTime && delete param.startTime;

    searchByDisplayTime(param, keycloak?.token ?? "")
      .then((res: any) => {
        // Set pagination information, if the total after filtered by displaytime is not enough
        // for the page => set page = 1
        setPaging({
          page: paging.limit && paging.page && res.data.total > (paging.limit * (paging.page - 1)) ? paging.page : 1,
          limit: paging.limit,
          total: res.data.assets.length,
        });

        onSearchSuccess(res.data);
        // Resize height of map incase have urgent alert
        if (res.data.urgentAlert?.content) {
          onUrgentAlertShow();
        }
      })
      .finally(() => setLoading(false));
  };

  // Get data base on search params
  const getData = (params: SearchFilter) => {
    setLoading(true);
    setForceReload(Date.now);
    searchShipments(params, keycloak?.token ?? "")
      .then((res) => {
        setPaging({
          page: params.page,
          limit: params.limit,
          total: res.data.total,
        });

        setShipmentId(prev => Array.from(new Set(res.data.assets.map((a) => a.shipmentId))).join())

        // If display time is not setting, set data for shipment and alert
        if (!displayTimeRef.current) {
          onSearchSuccess(res.data);

          // Resize height of map incase have urgent alert
          if (res.data.urgentAlert?.content) {
            onUrgentAlertShow();
          }
        } else {
          getDataByDisplayTime();
        }
      })
      .catch((err) => console.log(err))
      // If has display time => loading is true and wait until getDataByDisplayTime end
      .finally(() => {
        if (!displayTimeRef.current) {
          setLoading(false);
        }
      });
  };

  const clearSelectedMarker = () => {
    setSelectedMarker({});
  }

  const mapPanelData = (alerts: BaseAlert[]): MainAlertInfo[] => {
    return alerts?.map((data) => {
      return {
        gsId: data.gsId,
        shipmentId: data.shipmentId,
        dateTime: data.datetime,
        shipmentInfo: data.shipmentId + ";" + (timePath ? "&" + timePath : '' ),
        location: data.lat ? (data.lat?.toFixed(4)  + "; " + data.lng.toFixed(4)) : '-' ,
        description: data.description,
      } as MainAlertInfo;
    });
  };

  const onUrgentAlertShow = () => {
    // Resize screen height
    setScreenHeightWithoutMap(417);
    // Show urgent alert
    setShowUrgent(true);
  };

  const onUrgentAlertClosed = () => {
    setShowUrgent(false);
    setScreenHeightWithoutMap(358);
  };

  const getSearchParam = (paging: PagingInfo) => {
    // Get search param from local storage, if don't have, init with empty
    let searchHistoryParams = JSON.parse(
      sessionStorage.getItem("searchParams") || "{}"
    );
    if (Object.keys(searchHistoryParams).length === 0) {
      // Get customerId for search default
      const tokenObj = jwtDecode(keycloak.token!) as any;
      if (tokenObj.hasOwnProperty("customerId")) {
        searchHistoryParams.customerId = tokenObj.customerId;
      }
    }

    searchHistoryParams = {
      ...searchHistoryParams,
      page: paging.page,
      limit: paging.limit,
    };
    return searchHistoryParams;
  };

  const tabsItem = [
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <WarningOutlined 
            style={{ color: dataMaster.alertBgColor.Shock }} 
            alt="Shock Alert icon" 
          />
          Shock
        </span>
      ),
      key: "1",
      children: (
        <AlertList
          alertList={mapPanelData(assetSearchResult.shockAlerts)}
          tableConfig={shockAlertTableConfig()}
          selectedRow={selectedMarker}
          onRowClick={(row: MainAlertInfo) => {
            setSelectedMarker(row);
          }}
          screenHeightWithoutMap={screenHeightWithoutMap}
          reloadCounter={reloadCounter}
        ></AlertList>
      ),
    },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <img src="/icons/water.svg" alt="Water Damage icon" />
          Water Damage
        </span>
      ),
      key: "2",
      children: (
        <AlertList
          alertList={mapPanelData(assetSearchResult.waterDamages)}
          tableConfig={alertTableConfig()}
          selectedRow={selectedMarker}
          onRowClick={(row: MainAlertInfo) => {
            setSelectedMarker(row);
          }}
          screenHeightWithoutMap={screenHeightWithoutMap}
          reloadCounter={reloadCounter}
        ></AlertList>
      ),
    },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <ExclamationCircleFilled
            style={{ color: dataMaster.alertBgColor.security }}
            alt="Security Alert icon"
          />
          Security
        </span>
      ),
      key: "3",
      children: (
        <AlertList
          alertList={mapPanelData(assetSearchResult.securityAlerts)}
          tableConfig={securityAlertTableConfig()}
          selectedRow={selectedMarker}
          onRowClick={(row: MainAlertInfo) => {
            setSelectedMarker(row);
          }}
          screenHeightWithoutMap={screenHeightWithoutMap}
          reloadCounter={reloadCounter}
        ></AlertList>
      ),
    },
  ];

  const disabledDate = (current: Dayjs | null): boolean => {
    if (!current || (startDate && startDate > current) || (endDate && endDate < current)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={24} className="filter-field">
          <Col span={24}>
            <SearchForm
              onSearch={onSearch}
              masterData={masterData}
              onFilterDateChange={(date: RangeValue) => { setStartDate(date?.[0]); setEndDate(date?.[1]) }}
            ></SearchForm>
          </Col>
        </Row>
        {showUrgent && (
          <Row gutter={24}>
            <Col span={24} className="urgent-message">
              <Alert
                type="error"
                message={assetSearchResult.urgentAlert.content}
                showIcon
                closable
                afterClose={onUrgentAlertClosed}
                action={
                  <Button size="middle" danger>
                    <Link to="/security-dashboard">Detail</Link>
                  </Button>
                }
              />
            </Col>
          </Row>
        )}
        <Row gutter={24} className={"landing-page"}>
          <Col className="gutter-row" span={14}>
            <Row gutter={24}>
              <Col span={24}>
                <Form className="dipslay-time-form" layout="inline">
                  <Form.Item label="Refresh Interval" name="intervalTime">
                    <Select
                      options={dataMaster.interValTime}
                      onChange={(value) => onChangeIntervalTime(value)}
                      defaultValue={15}
                      allowClear={false}
                      style={{ width: "150px" }}
                    />
                  </Form.Item>
                  <Form.Item label="Display time" name="displayTime">
                    <DatePicker
                      showTime
                      placeholder={"YYYY-MM-DD HH:mm:ss"}
                      disabled={isDisableDisplayTime}
                      disabledDate={disabledDate}
                      onChange={(value) => onChangeDisplayTime(value)}
                      value={displayTime ? (moment(displayTime) as any) : null}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row gutter={24} className="mt-10">
              <Col span={24}>
                {assetSearchResult && (
                  <Map
                    height={`calc(100vh - ${screenHeightWithoutMap}px)`}
                    dashboardData={assetSearchResult}
                    selectedMarker={selectedMarker}
                    setSelectedMarker={setSelectedMarker}
                    timePath={timePath}
                  ></Map>
                )}
                <AntPagination
                  size="small"
                  className="mt-10"
                  showSizeChanger={true}
                  current={paging.page || 1}
                  total={paging.total || 0}
                  pageSize={paging.limit}
                  onChange={(page: number, pageSize: number) =>
                    onPageChange(page, pageSize)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xl={10} lg={10}>
            <Tabs type="card" defaultActiveKey="1" items={tabsItem}></Tabs>
          </Col>
        </Row>
        <LatestAlert latestAlerts={latestAlerts}></LatestAlert>
      </Spin>
    </>
  );
};

export default MainDashBoard;