import { Divider, Typography } from 'antd';
import { MarkerInfo } from '../../models/marker';

interface ShockAlertDetailPanelProps {
  shockAlert: MarkerInfo
}

function ShockAlertDetailPanel(props: ShockAlertDetailPanelProps) {
  const { shockAlert } = props;
  return (
    <div className='shock-drawer'>
        <Typography className='line-label'>
          Date Time
        </Typography>
        <Divider/>
        <Typography>
          {shockAlert?.datetime}
        </Typography>

        <Typography className='line-label'>
          Location
        </Typography>
        <Divider />
        <Typography>
          {shockAlert?.lat && shockAlert?.lng ? shockAlert?.lat.toFixed(4) + '; ' + shockAlert?.lng?.toFixed(4) : ''}
        </Typography>

        <Typography className='line-label'>
          P(Breakage)
        </Typography>
        <Divider />
        <Typography>
          {shockAlert?.pbreach}%
        </Typography>

        <Typography className='line-label'>
          Description
        </Typography>
        <Divider />
        <Typography>
          {shockAlert?.description}
        </Typography>
    </div>
  );
}

export default ShockAlertDetailPanel;
