import axios from "axios";

export const get = (path: string, token: string, params = {}): Promise<any> => {
  return axios.get<any>(`${process.env.REACT_APP_IRA_ENDPOINT}${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  }).then(res => res.data)
  .catch(err => Promise.reject(err.response ? err.response.data : err));
};
