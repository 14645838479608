import { WarningOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { shockAlertTableConfig } from './shock-alert-table-config';
import dataMaster from '../../configs/data-master.json';
import '../../styles/shock-alert-table.scss'
import { MarkerInfo } from '../../models/marker';

interface ShockAlertTableProps {
  shockAlertList: MarkerInfo[],
  onRowClick: (row: MarkerInfo) => void;
}

function ShockAlertTable(props: ShockAlertTableProps) {
  const { shockAlertList, onRowClick } = props;

  return (
    <>
        <div className='shock-header-table table-title'>
          <WarningOutlined style={{ color: dataMaster.alertBgColor.Shock, margin: '0 10px' }} />
          <span className='shock-title-header-table'>
            Shock Alerts
          </span>
        </div>
        <Table
          size="small"
          className="shock-table"
          onRow={(record: MarkerInfo) => {
            return {
              onClick: () => {
                onRowClick?.(record)
              }
            }
          }}
          columns={shockAlertTableConfig() as any}
          dataSource={shockAlertList && shockAlertList.map((alert, index) => ({ ...alert, key: index }))}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 50,
            pageSizeOptions: [50, 100, 150],
          }}
        />
    </>
  );
}

export default ShockAlertTable;
