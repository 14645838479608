import { ExclamationCircleFilled, RightOutlined, WarningOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dataMaster from '../../configs/data-master.json';
import  "../../styles/shipment-risk-info.scss";

interface RiskInfoProps {
  gsId: string,
  shipmentId: string,
  timePath?: string,
}

const ShipmentRiskInfo = (props: RiskInfoProps) => {

  const { gsId, shipmentId, timePath } = props;

  return (
    <div className="shipment-info-window-wrapper">
      <p className="info-window-header">Shipment ID: {shipmentId}<br /><span className='mr-17'>Device ID</span>: {gsId}</p>
      
      <div className="info-window-content">

        {(
        <Link to={{
          pathname: "/security-dashboard",
          search: 'shipmentId=' + shipmentId + (timePath ? '&' + timePath : '')
        }} target="_blank">
          <div className="info-window-content-item">
          <ExclamationCircleFilled className='security-icon' style={{color: dataMaster.alertBgColor.security}} alt='Security Alert icon' />
            <label>Security Alerts</label>
            <RightOutlined />
          </div>
        </Link>
        )}

        {(
        <Link to={{
          pathname: "/water-damage-dashboard",
          search: 'shipmentId=' + shipmentId + (timePath ? '&' + timePath : '')
        }} target="_blank">
          <p className="info-window-content-item">
            <img src="/icons/water.svg" alt='Water Damage icon' />
            <label>Water Damage</label>
            <RightOutlined />
          </p>
        </Link>
        )}
        
        {(
        <Link to={{
            pathname: "/shock-dashboard",
            search: 'shipmentId=' + shipmentId + (timePath ? '&' + timePath : '')
          }} target="_blank">
          <p className="info-window-content-item">
            <WarningOutlined className='shock-icon' style={{color: dataMaster.alertBgColor.Shock}} alt='Shock Alert icon' />
            <label>Shock Alerts</label>
            <RightOutlined />
          </p>
        </Link>
        )}
      </div>
    </div>
  );
};

export default ShipmentRiskInfo;
