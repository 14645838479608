import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { Alert as AlertANTD, Col, Drawer, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import Map from '../../components/map/map.component';
import { NoData } from '../../components/no-data/no-data.component';
import ShockAlertDetailPanel from '../../components/shock-alert-detail/shock-alert-detail.component';
import ShockAlertTable from '../../components/shock-alert-table/shock-alert-table.component';
import keycloak from '../../configs/keycloak';
import { ShockAlert, ShockData } from '../../models';
import { getShockInfo } from '../../services/shock-service';
import dataMaster from '../../configs/data-master.json';
import '../../styles/shock-dashboard.scss';
import { MarkerInfo } from '../../models/marker';
import { useDocumentTitle } from '../../hooks/use-document-title';

function ShockDashboard() {
  useDocumentTitle("Shock Alerts Dashboard");
  const [shockDetail, setShockDetail] = useState<ShockData>();
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<MarkerInfo>({isNotSelet: true});

  useEffect(() => {
    setLoading(true);
    const url = new URL(window.location.href);
    const startTime = url.searchParams.get("startTime") ?? '';
    const endTime = url.searchParams.get("endTime") ?? '';
    const shipmentId = url.searchParams.get("shipmentId") ?? '';

    getShockInfo(shipmentId, startTime, endTime, keycloak.token ?? "")
      .then(res => {
        if (res?.data) {
          setHasData(true);
          setShockDetail(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsOpenDrawer(!selectedMarker.isNotSelet);
  }, [selectedMarker])

  const mapPanelData = (shocks: ShockAlert[]): MarkerInfo[] => {
    return shocks.map(data => {
      return {
        dateTime: data.datetime,
        location: data.lat && data.lng ? (data.lat.toFixed(4)  + "; " + data.lng.toFixed(4)) : '-',
        description: data.description,
        datetime: data.datetime,
        lat: data.lat,
        lng: data.lng,
        pbreach: data.pbreach,
        
      };
    });
  };

  return !hasData ? (
    <NoData loading={loading} />
  ) : (
    <>
      <Spin spinning={loading}>
        <Row gutter={[24, 24]} className={'shock-page'}>
          <Col className="gutter-row" span={16}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="gutter-row buffer">
                <AlertANTD
                  message={`Cumulative P(Breakage): ${!shockDetail?.alerts || shockDetail?.alerts.length === 0 ||  !shockDetail?.cumulativeBreakage  ? 0 :  shockDetail?.cumulativeBreakage}%`}
                  showIcon
                  icon={<ExclamationCircleFilled style={{ color: dataMaster.alertBgColor.Shock }} />}
                  className='cumulative-shock'
                />
              </Col>
              <Col span={24} className="gutter-row">
                <div className='chart' id="timeline-container">
                  <iframe className='iframe-chart' src={shockDetail?.embedUrls[0]} width="100%" height="100%"></iframe>
                </div>
              </Col>
              <Col span={24} className="gutter-row">
                <Map
                  height={'calc(100vh - 512px)'}
                  customLegend={{ icon: '/icons/shock.svg', name: 'Shocks' }}
                  shockDetail={shockDetail}
                  selectedMarker={selectedMarker}
                  setSelectedMarker={setSelectedMarker}
                ></Map>
              </Col>
              <Col span={24} className="gutter-row">
                <div className='chart' id="enegry-container">
                  <iframe className='iframe-chart' src={shockDetail?.embedUrls[1]} width="100%" height="100%"></iframe>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[24, 24]}>
              <Col className="gutter-row">
                <ShockAlertTable
                  shockAlertList={mapPanelData(shockDetail?.alerts ?? [])}
                  onRowClick={(row: MarkerInfo) => {
                    setSelectedMarker(row);
                  }}
                ></ShockAlertTable>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      <Drawer
        className="drawer"
        placement="right"
        open={isOpenDrawer}
        mask={false}
        closable={true}
        onClose={() => {setIsOpenDrawer(false)}}
        width={'33%'}
        title={
          <span className="shock-title-panel">
            <WarningOutlined
              className="shock-alert-icon"
              style={{ color: dataMaster.alertBgColor.Shock }}
            />
            Shock Alert Details
          </span>
        }
      >
        <ShockAlertDetailPanel
          shockAlert={selectedMarker}
        ></ShockAlertDetailPanel>
      </Drawer>
    </>
  );
}

export default ShockDashboard;