const securityTableConfig = () => [
    {
      title: "Date Time",
      dataIndex: 'dateTime',
      key: 'datetime',
      width: '9%',
      ellipsis: true,
      defaultSortOrder: 'descend',
      sorter: (a: {dateTime: string}, b: {dateTime: string}) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
    },
    {
      title: "Location",
      dataIndex: 'location',
      key: 'location',
      width: '10%',
      ellipsis: true,
    },
    {
      title: "P(Breach)",
      dataIndex: 'pbreach',
      key: 'pbreach',
      width: '11%',
      ellipsis: true,
    },
  ];
  
  export { securityTableConfig };
  